/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    strong: "strong",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Makefileを使うと長いscriptを保存して、簡単なコマンドで使えるようになる\ndockerやgolangのプロジェクトでよく見かける\n(もともとMakefileとはなんなのかよくわからないまま書いている)"), "\n", React.createElement(_components.h3, {
    id: "基本の書き方",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%9F%BA%E6%9C%AC%E3%81%AE%E6%9B%B8%E3%81%8D%E6%96%B9",
    "aria-label": "基本の書き方 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "基本の書き方"), "\n", React.createElement(_components.p, null, "以下のような規則で書く必要がある"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"makefile\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-makefile line-numbers\"><code class=\"language-makefile\"><span class=\"token target symbol\">target</span><span class=\"token punctuation\">:</span> dependencies\n    system command<span class=\"token punctuation\">(</span>s<span class=\"token punctuation\">)</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "target: ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">makefile</code>"
    }
  }), "コマンドで呼び出すときの名前"), "\n", React.createElement(_components.li, null, "dependencies: targetが実行される前に呼び出されるtarget"), "\n", React.createElement(_components.li, null, "system command(s): 実行されるスクリプト"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "コマンド行はtabでインデントしないと動かない"), "ので注意"), "\n", React.createElement(_components.p, null, "例）"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Makefile</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"makefile\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-makefile line-numbers\"><code class=\"language-makefile\"><span class=\"token comment\"># comment</span>\n<span class=\"token target symbol\">shell-mkdir</span><span class=\"token punctuation\">:</span>\n  mkdir test/\n<span class=\"token target symbol\">shell-ls</span><span class=\"token punctuation\">:</span>shell-mkdir\n  ls</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">makefilefile</code>"
    }
  }), "のあるディレクトリにて\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">makefile shell-ls</code>"
    }
  }), "を実行すると、", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">test</code>"
    }
  }), "ディレクトリが作られた後に", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">ls</code>"
    }
  }), "コマンドが実行される"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">make</span> shell-ls\n<span class=\"token function\">mkdir</span> <span class=\"token parameter variable\">-p</span> test/\n<span class=\"token function\">ls</span> \n<span class=\"token builtin class-name\">test</span>        Makefile</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "変数を扱う",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%A4%89%E6%95%B0%E3%82%92%E6%89%B1%E3%81%86",
    "aria-label": "変数を扱う permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "変数を扱う"), "\n", React.createElement(_components.p, null, "シェルみたいに定義できる"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Makefile</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"makefile\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-makefile line-numbers\"><code class=\"language-makefile\">SSH_KEY<span class=\"token operator\">=</span>`cat ~/.ssh/id_rsa\n<span class=\"token target symbol\">install</span><span class=\"token punctuation\">:</span> \n\tdocker build --build-arg SSH_KEY<span class=\"token operator\">=</span><span class=\"token string\">\"$(SSH_KEY)\"</span> .\n\tdocker build -f Dockerfile.test --build-arg SSH_KEY<span class=\"token operator\">=</span><span class=\"token string\">\"$(SSH_KEY)\"</span> </code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "引数を扱う",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%BC%95%E6%95%B0%E3%82%92%E6%89%B1%E3%81%86",
    "aria-label": "引数を扱う permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "引数を扱う"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">?=</code>"
    }
  }), "を使うことでデフォルトの値を代入できる"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "\n        <div class=\"undefined\">\n          <span>Makefile</span>\n        </div>\n       "
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"makefile\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-makefile line-numbers\"><code class=\"language-makefile\">SSH_KEY<span class=\"token operator\">=</span>`cat ~/.ssh/id_rsa`\nDOCKER_REPO<span class=\"token operator\">?=</span>test\nDOCKER_TAG<span class=\"token operator\">?=</span>1.0\n\n<span class=\"token target symbol\">docker-image</span><span class=\"token punctuation\">:</span>\n\tdocker build --build-arg SSH_KEY<span class=\"token operator\">=</span><span class=\"token string\">\"$(SSH_KEY)\"</span> -t <span class=\"token string\">\"$(DOCKER_REPO)\"</span><span class=\"token punctuation\">:</span><span class=\"token string\">\"$(DOCKER_TAG)\"</span> .</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h4, {
    id: "引数指定なし",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%BC%95%E6%95%B0%E6%8C%87%E5%AE%9A%E3%81%AA%E3%81%97",
    "aria-label": "引数指定なし permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "引数指定なし"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">make</span> docker-image\n<span class=\"token punctuation\">..</span>.\n\n<span class=\"token function\">docker</span> images\nREPOSITORY          TAG                 IMAGE ID            CREATED              SIZE\n<span class=\"token builtin class-name\">test</span>                <span class=\"token number\">1.0</span>                 234526e3b4d5        About a minute ago   42MB</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h4, {
    id: "引数指定あり",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%BC%95%E6%95%B0%E6%8C%87%E5%AE%9A%E3%81%82%E3%82%8A",
    "aria-label": "引数指定あり permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "引数指定あり"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">make</span> docker-image <span class=\"token assign-left variable\">DOCKER_REPO</span><span class=\"token operator\">=</span>test_arg <span class=\"token assign-left variable\">DOCKER_TAG</span><span class=\"token operator\">=</span><span class=\"token number\">1</span>.0_arg\n<span class=\"token punctuation\">..</span>.\n\n<span class=\"token function\">docker</span> images\nREPOSITORY          TAG                 IMAGE ID            CREATED             SIZE\ntest_arg            <span class=\"token number\">1</span>.0_arg             234526e3b4d5        <span class=\"token number\">7</span> minutes ago       42MB</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "http://www.unixuser.org/~euske/doc/makefile/"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackoverflow.com/questions/2826029/passing-additional-variables-from-command-line-to-make"
  }, "makefile - Passing additional variables from command line to make - Stack Overflow")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Makefile"
  }, "Makefile - Wikipedia")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
